<template>
  <div>
    <div class="page_title_contain">
      <header class="other_page_head">
        <div class="common_warp header_wrap">
          <div
            class="icon"
            @click="
              () => {
                $router.push('/home')
              }
            "
          >
            <img src="../assets/eamaster_logo_title_index.png" alt="" />
          </div>
          <div class="menu index_page_menu">
            <p
              class="menu_item"
              @click="
                () => {
                  $router.push('/more')
                }
              "
            >
              全部活动
            </p>
            <p
              class="menu_item"
              @click="
                () => {
                  $router.push('/about')
                }
              "
            >
              关于我们
            </p>
            <a-input-search
              class="input_search"
              placeholder="夏校、机器人、耶鲁、纽约…"
              enter-button="搜索"
              size="large"
              v-model="keyword"
              @search="onSearch"
              :maxLength="100"
            >
              <a-icon
                slot="prefix"
                type="search"
                :style="{ color: '#cdcdcd' }"
              />
            </a-input-search>
          </div>
          <div class="language other_page_language">
            <span class="pointer">中文</span>
            <span class="line">|</span
            ><span
              class="pointer"
              @click="
                () => {
                  $router.push('english')
                }
              "
              >English</span
            >
          </div>
        </div>
      </header>
    </div>
    <div class="page_wrap">
      <div class="about">
        <div class="about_left">
          <div class="about_item" id="intro">
            <div class="about_title">关于我们</div>
            <div class="website_logo">
              <img src="../assets/eamaster_logo.png" alt="" />
            </div>
            <div class="about_text_content">
              <p>
                EAmaster课外大师是一个为7至12年级学生提供课外活动信息的平台，专注于为准备出国留学的青少年提供广泛且可靠的活动信息。
                通过各种渠道的悉心收集，我们的网站及小程序所罗列的活动横跨十多个类别，包括暑期课程、学术竞赛、商业竞赛、科学研究和社区服务等，这些活动都经过我们团队成员的严格审查和筛选。
              </p>
              <p>
                EAmaster课外大师也为促进青少年的成长而效力。我们所出版的《课外白皮书》和在线内容能够为学生、家长和教育工作者提供便利，帮助他们寻找国内外符合学生兴趣的课外活动。除了在校成绩外，我们相信课外活动能展示学生的独特性，能最大限度地发挥学生的学习潜力，促进个人发展。
              </p>
              <p>
                我们区别于中国的其他信息平台，EAmaster课外大师聚精于解决国内外信息不对称的问题，作为公正的第三方，我们为学生、家长和教育工作者们提供全面、客观的信息。我们与著名大学及其他活动组织方深度合作，核实每项活动的可信度，深挖具有代表性的活动。通过严格的程序，过滤及验证我们所推广的课外活动，确保提供的信息是值得信赖且可靠的，以便为学生提供高质量活动的参与信息。此外，我们的团队由哥伦比亚大学、加州大学洛杉矶分校、哈佛大学和纽约大学的毕业生组成。
              </p>
            </div>
          </div>
          <!-- <div class="about_item" id="partners">
            <div class="about_title">合作伙伴</div>
            <div class="partners_images">
              <img
                v-for="item in partners"
                :src="require(`@/assets/parters/${item}`)"
                alt=""
                :key="`${item}`"
              />
            </div>
          </div>
          <div class="about_item" id="business">
            <div class="about_title">商务合作</div>
            <div class="about_text_title">合作原则</div>
            <div class="about_text_content">
              <p>
                我课外大师是国内首个基于互联网的中学生课外活动资讯平台。
                依托于行业内顶尖的美国大学前招生官组成的顾问团队，我们为用户收集并筛选了全面准确的优质课外活动信息。我们的主要用户是国内有留学意向的初高中学生，他们年轻、有梦想、有追求。
              </p>
              <p>
                你有活动，我有平台！我们将诚意推荐所有适合中学生的优质活动！
              </p>
            </div>
            <div class="about_text_title">媒体及商务合作联系</div>
            <div class="about_text_content">
              <p>BD@eamaster.com</p>
            </div>
          </div> -->
          <div class="about_item" id="website">
            <div class="about_title">网站服务</div>
            <div class="about_text_content">
              <!-- <p>
                <a class="web_name" href="http://www.eamaster.com">课外大师</a>
                <a href="http://www.eamaster.com">（www.eamaster.com）</a>
                是基于互联网的青少年课外活动交流平台，为初高中学生尤其是有出国深造意向的学生、家长和升学指导提供全面准确的课外活动信息。
              </p> -->
              <p>
                通过不断与大学、高中和其他活动组织方的深入合作，我们挖掘出了有关暑期课程、学术竞赛、社区服务等的最新信息。在EAmaster课外大师平台，各种背景的学生都能轻松了解到与他们兴趣和技能相匹配的课外活动。
              </p>
              <p>
                您可以通过以下方式进行活动检索。登录eamaster.com网站，进行关键词搜索，在搜索栏中输入您要寻找的活动类别即可。此外，您还可以按学科领域、活动日期、参与年级等方式筛选您想要的活动。
              </p>
              <p>
                在校的教育工作者可以与我们联系，获得一份免费的
                《课外活动白皮书》，这是一份包含各类活动选择的年度目录。
              </p>
            </div>
          </div>
        </div>
        <!--    <div class="about_anchor">
          <a-anchor :get-current-anchor="getCurrentAnchor" @change="onChange">
            <a-anchor-link href="#/about#intro" title="平台简介" />
            <a-anchor-link href="#/about#partners" title="合作伙伴" />
            <a-anchor-link href="#/about#business" title="商务合作" />
            <a-anchor-link href="#/about#website" title="网站服务" />
          </a-anchor>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      partners: [
        'parter1.png',
        'parter2.png',
        'parter3.png',
        'parter4.png',
        'parter5.png',
        'parter6.png',
        'parter7.png',
        'parter8.png',
        'parter9.png',
      ],
      activeAnchor: '#/about#intro',
      keyword: '',
    }
  },
  mounted() {
    /*  if (this.$route.hash) {
      this.activeAnchor = '#' + this.$route.fullPath
      document.querySelector(this.$route.hash).scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      })
    } */
  },
  methods: {
    getCurrentAnchor() {
      return this.activeAnchor
    },
    onChange(link) {
      this.activeAnchor = link
    },
    onSearch() {
      this.$router.push({
        params: {
          keyword: this.keyword,
        },
        name: 'more',
      })
    },
  },
}
</script>

<style lang="less" scoped>
.page_title_contain {
  background-color: #fff;
  .common_warp,
  .slogan_image {
    position: relative;
    z-index: 1;
  }

  header .slogan_wrap {
    position: absolute;
    z-index: 0;
  }
  header .menu .menu_item {
    color: #333333;
  }
}
.page_wrap {
  display: flex;
  justify-content: center;
  background: #f6f7f7;
}

.about {
  // width: 856px;
  width: 1200px;
  background: #ffffff;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
  margin-top: 24px;
  margin-bottom: 100px;
  // padding-right: 40px;
  display: flex;
  .about_left {
    // width: 1074px;
    background: #ffffff;
    margin-top: 24px;
    padding: 40px;
    // padding-right: 16px;
  }

  .about_item {
    width: 100%;
    margin-bottom: 60px;

    .about_title {
      width: 100%;
      font-size: 24px;
      font-family: AlibabaPuHuiTiM;
      color: #333333;
      line-height: 30px;
      padding-bottom: 16px;
      border-bottom: 1px solid #f1f2f0;
      margin-bottom: 32px;
    }

    .about_text_title {
      height: 27px;
      font-size: 20px;
      font-family: AlibabaPuHuiTiM;
      color: #333333;
      line-height: 27px;
    }

    .about_text_content {
      margin-top: 16px;
      margin-bottom: 24px;

      p {
        font-size: 18px;
        font-family: AlibabaPuHuiTiR;
        color: #333333;
        line-height: 28px;

        .web_name {
          font-weight: bold;
        }

        a {
          color: #333;
        }
      }

      p + p {
        margin-top: 16px;
      }
    }

    .website_logo {
      margin-bottom: 32px;
      text-align: center;

      img {
        width: 120px;
        height: 177px;
      }
    }

    .partners_images {
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      // justify-content: space-between;
      height: 310px;

      img {
        width: 223px;
        height: 82px;
        margin-right: 34px;
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }

  .about_item:last-child {
    margin-bottom: 0;
  }
}

.about_anchor {
  position: relative;
  top: 184px;
  left: 9px;
  width: 109px;
  height: 192px;

  :deep(.ant-anchor-wrapper) {
    background-color: unset;
  }

  :deep(.ant-anchor-link) {
    padding: 12px 0 12px 17px;
  }

  :deep(.ant-anchor-link-active > .ant-anchor-link-title) {
    color: #ff7200;
  }

  :deep(.ant-anchor-ink-ball) {
    border-radius: unset;
    border-left: 9px solid #ff7200;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: unset;
    background-color: #f6f7f7;
    transform: translateX(0);
    transform: translatey(-2px);
  }
}
</style>
